import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const HoneyMoonkPage = () => (
  <Layout>
    <SEO title="Honey Moon" />
    <div className="drawer article-content">
      <h1 className="article-title">Luna de miere</h1>

      <section>
        <p>
          După cea mai frumoasă zi din viața voastră urmează și o vacanță/o
          escapadă în care doar voi doi să vă bucurați de timp împreună.
        </p>
        <p>
          Indiferent de cât de scurtă va fi această vacanță și de destinația
          aleasă este important să vă luați totuși măcar câteva zile pentru voi,
          să vă relaxați după stresul cu organizarea nunții, după oboseala din
          ziua nunții, dar să vă și bucurați reflectând la evenimentul major din
          viața voastră de cuplu.
        </p>
        <p>
          Nu uitați, luna de miere este importantă pentru voi, așadar voi
          alegeți locația sau activitățile pe care vi le doriți, nu contează
          dacă tot ceilalți vă sfătuiesc să mergeți la mare sau în destinații
          exotice.
        </p>
      </section>

      <h2 className="paragh-title">Luna de miere în România</h2> <br/>

      <h3 className="paragh-title">Dacă alegi marea</h3>
      <section>
        <ul>
          <li>glampingul din Corbu, Teepee, la 251 km de București;</li>
          <li>
            Tuya Cabin, Agigea. O cazare deosebită, cu pereți de sticlă, aflată
            în pădure, în apropierea plajei din Eforie Nord.
          </li>
        </ul>
      </section>

      <h3 className="paragh-title">Munte</h3>
      <section>
        <ul>
          <li>glampingul din Azuga, Wegloo</li>
          <li>glampingul din Argeș, Ursa Mica Glamping Resort</li>
          <li>Cabana Berg aflată la 30 km de Cluj</li>
          <li>Retreat Mărgău Apuseni</li>
        </ul>
      </section>

      <h3 className="paragh-title">Și munte și mare</h3>
      <section>
        <ul>
          <li>lacul Colibița</li>
          <li>plaja sălbatică de la Șuncuiuș (în județul Bihor)</li>
        </ul>
      </section>

      <h3 className="paragh-title">Dacă vrei să te cazezi într-un castel</h3>
      <section>
        <ul>
          <li>Zabola Estate, în Covasna</li>
          <li>Palatul Brukenthal, în Avrig, jud Sibiu</li>
          <li>Vila Golescu, din Câmpulung Muscel</li>
          <li>Castel Daniel, în Covasna</li>
        </ul>
      </section>

      <h3 className="paragh-title">Alte cazări inedite din România</h3>
      <section>
        <ul>
          <li>
            Căsuțe în copac: Porumbacu Tree House (în județul Sibiu),
            Transylvania Log Cabins (în județul Hunedoara), Vadul Lupilor (în
            județul Maramureș)
          </li>
          <li>
            Cazări cu piscine deosebite: Pensiunea Ecaterina (în județul Caraș
            Severin), Diamond & Ovi ((în județul Hunedoara), Mara (județul
            Maramureș), Hotelul Mahmudia din Tulcea.
          </li>
          <li>
            Haita Land în județul Suceava, Balvanyos Resort în județul Covasna,
            Valea celor XII în județul Neamț, Tiny Transylvania în județul Cluj.
          </li>
        </ul>
      </section>

      <h3 className="paragh-title">Luna de miere în Europa</h3>
      <section>
        <ul>
          <li>
            Dacă alegi Grecia poți merge în Santorini (de preferat cu avionul,
            drumul cu mașina fiind de aproximativ 23 de ore), Lefkada (14h de
            mers cu mașina,iar cu avionul poți merge până în Preveza, care se
            află la 15km de orașul Lefkada), Kavala (poți ajunge atât cu
            avionul, cât și cu mașina și drumul durează cam 8h), Zakynthos (22
            de ore cu mașina), Mykonos (18 ore cu mașina) Kos (20 de ore cu
            mașina).
          </li>
          <li>Croația</li>
          <li>Spania (Tenerife, Mallorca, Alicante)</li>
        </ul>
      </section>

      <h3 className="paragh-title">Destinații mai exotice pentru luna de miere</h3>
      <section>
        <ul>
          <li>
          Bali
          </li>
          <li>Maldive</li>
          <li>Thailanda</li>
          <li>Sri Lanka</li>
          <li>Madagascar</li>
          <li>Republica Dominicană</li>
        </ul>
      </section>

      <section>
        <p>
          Indiferent unde alegeți să vă petreceți luna de miere, nu uitați:
        </p>
        <ul>
          <li>să rezolvați cu reînnoirea actelor după cununia actelor, dacă vă schimbați numele</li>
          <li>verificați dacă sunt necesare vaccinuri pentru destinația aleasă de voi</li>
          <li>bucurați-va de timpul petrecut în do</li>
        </ul>
      </section>

      <div className="nav-section">
        <Link to="/guestBook">◀ Guest Book</Link>
      </div>
    </div>
  </Layout>
)

export default HoneyMoonkPage
